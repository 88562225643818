<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3
							b Brands - {{this.form.type}} new entity
			//pre {{this.options.users}}
			el-form(:form="form" :model="form" ref="form" :rules="rules" )
				el-row.text-left()
					h5 Brand Details
					p Enter the brand name and the url. You can also assign the brand to the relevant sales agent.
				el-row(:gutter="12")
					el-col(:xs="24", :sm="8"  :xl="4")
						el-form-item(label="Name" prop="name")
							el-input(v-model="form.name" placeholder="Brand name" @blur="copyToGlobal")
					el-col(:xs="24", :sm="8" :xl="4")
						el-form-item(prop="global_name")
							template(slot="label")
								span.label-color-override Global Name&nbsp;
									small.text-success (uppercase, used for budgets)
							el-input(v-model="form.global_name" placeholder="Brand name")
					el-col(:xs="24", :sm="8"  :xl="4")
						el-form-item(label="Assignee ID" prop="uuid")
							el-select.w-100(v-model="form.user_id" placeholder="Select a user" filterable="")
								el-option(label="- None, Select an assignee -" :value="''")
								el-option(v-for="item in options.users" :key="item.dx" :label="`${item.t} - (${item.n}${item.e ? (`, ${item.e})`) : ')' }`" :value="item.v")
					el-col(:xs="24", :sm="24" :xl="12")
						el-form-item(label="Brand URL" prop="url")
							el-input( v-model="form.url" placeholder="Brand URL")
					el-col(:span="24")
						el-form-item(label="Comments" prop="comments")
							el-input(type="textarea" :rows="5" v-model="form.comments" placeholder="Lorem Ipsum Dolor Sit Amet" :maxlength="3000" show-word-limit="")

				el-divider

				el-row.text-left()
					h5 Working Hours
					p Set the working hours and toggle it on or off. If a lead is registered not in the working hours, from his perspective he will be registered on a normal basis (will be redirected to the "thankyou page"), but, will be saved as a failed lead with the failed reason of "out of working hours" and won't send it to the brand.
					p The working hours are set for each day of the week. If you toggle of a day, the brand won't receive leads on that day.
					p.text-success
						i *Note: The timezone of the working hours is the server's timezone which is UTC+3
				el-row(:gutter="12")
					el-col(:xs="12" :sm="6" )
						el-form-item(label="Toggle working hours" position="left")
							el-switch(v-model="form.working_hours_flag" active-text="On" inactive-text="Off" )

				el-row(:gutter="12" v-if="form.working_hours_flag")
					el-col(:span="24")
						el-row.mt-2(:gutter="12" v-for="row of form.working_hours_list" :key="row.label")
							el-col.text-left(:xs="12" :sm="6" :md="4" :xl="2")
								label.label-color-override {{row.label}}
							el-col(:xs="12" :sm="6" :md="3" :xl="2")
								el-switch.w-100(v-model="row.flag" active-text="On" inactive-text="Off" )

							el-col(v-if="row.flag" :xs="12" :sm="6" :md="4")
								el-time-select.w-100(v-model="row.start" placeholder="Start time" size="mini" :picker-options="timePickerOptions.full")
							el-col(v-if="row.flag" :xs="12" :sm="6" :md="4")
								el-time-select.w-100(v-model="row.end" placeholder="End time" size="mini" :picker-options="timePickerOptions.full")

				el-divider
				el-row.text-left()
					h5 Brand Logo
					p Upload a brand logo or link to an image URL
				el-row(:gutter="12")
					el-col(:xs="24" :sm="12")
						el-form-item(label="Link to brand logo" :error="errors.img_src.s ? errors.img_src.m : ''" v-bind:class="{'is-invalid': errors.img_src.s}")
							el-input(v-model="form.img_src" placeholder="URL link to the image logo")
					el-col(:xs="24" :sm="12")
						el-form-item(label="Upload logo")
							el-upload.upload-demo(
								drag="" :action="`${api_path}file/upload`" w thumbnail-mode="" :disabled="list.length >= 1" v-bind:class="{'disabled-field': list.length >= 5}" :on-success="handleSuccess" :file-list="fileList" multiple="" :headers="auth" :limit="3" :before-upload="beforeUpload")
								i.el-icon-upload
								.el-upload__text
									| Drop file here or
									em click to upload
								.el-upload__tip(slot='tip') jpg/png files with a size less than 4MB - Max 5 files
				el-row(:gutter="12" justify="between")
					el-button(@click="submit" type="primary") Save
					el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel


</template>


<style lang="scss">

</style>


<script lang="js">
import {notEmpty, validateAlphaNumeric, validateAlphaNumericAndExists} from "@/utils/validate";
import {daysOfWeek, workingDaysOfWeek, timePickerOptions} from "@/struct";

const VALID = [
	'image/png',
	'image/bmp',
	'image/jpg',
	'image/jpeg',
	'image/gif',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];

import validator from 'validator';
import Vue from 'vue';

let clearBeforeSend = (data) => {
	let body = {...data};
	delete body.type;
	return body;
};

export default {
	name: 'brand-form',
	async beforeRouteEnter(to, from, next) {
		if (to.name === 'iframe-add-brand') {
			const options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '6e1430cf-6c24-4da7-aeaf-a63579441e45'});
			next(vm => {
				vm.options.users = options.users;
			});
		} else if (to.name === 'iframe-edit-brand') {
			try {
				const options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: '6e1430cf-6c24-4da7-aeaf-a63579441e45'});
				let result = await Vue.apix.sendHttpRequest('GET', 'brand/get', {id: to.params.id});
				next(vm => {
					vm.entity_id = to.params.id;
					vm.originalName = result.name;
					vm.form = {
						type: 'edit',
						name: result.name,
						global_name: result.global_name,
						url: result.url,
						allow_duplicates: result.allow_duplicates,
						img_src: result.img_src,
						user_id: result.user_id ? result.user.uuid : '',
						working_hours_list: result.working_hours_list,
						working_hours_flag: result.working_hours_flag,
						comments: result.comments || ''
					};

					vm.options.users = options.users;

					vm.rules.name.original = result.name;
					vm.rules.name.action = 'edit';
				});
			} catch (e) {
				Vue.router.push({name: 'brand-list'})
			}
		}
	},
	data() {
		return {
			originalName: '',
			daysOfWeek,
			timePickerOptions,
			form: {
				type: 'add',
				name: '',
				global_name: '',
				url: '',
				img_src: '',
				user_id: '',
				allow_duplicates: false,
				working_hours_flag: false,
				working_hours_list: workingDaysOfWeek,
				comments: ''
			},
			rules: {
				name: {required: true, trigger: 'blur', validator: validateAlphaNumericAndExists, topic: 'brand', app: this,},
				global_name: {required: true, trigger: 'blur', validator: validateAlphaNumeric, topic: 'brand', app: this,},
				url: {required: true, trigger: 'blur', validator: notEmpty},
				img_src: {required: false, trigger: 'blur'},
				comments: {required: false, trigger: 'blur'}
			},
			errors: {
				img_src: {s: false, m: ''}
			},
			busy: false,
			valid: VALID,
			fileList: [],
			list: [],
			options: {
				users: []
			},
		}
	},
	computed: {
		api_path() {
			return process.env.VUE_APP_BASE_URL
		},
		auth() {
			return {Authorization: `Bearer ${this.$store.state.AUTH_TOKEN}`}
		},
	},
	methods: {
		async submit() {
			this.$refs.form.validate(async valid => {
				if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
				try {
					if (this.form.type === 'add') {
						let result = await Vue.apix.sendHttpRequest('POST', `brand/create`, {data: clearBeforeSend(this.form)})
						if (result.id) this.$router.push({name: 'iframe-list'})
					} else {
						let result = await Vue.apix.sendHttpRequest('PUT', `brand/${'update/' + this.entity_id}`, {data: clearBeforeSend(this.form)})
						this.$router.push({name: 'iframe-list'})
					}
				} catch (e) {
					console.error(e);
				}
			})
		},

		handleSuccess() {
			// TODO - assign the link to the brand
		},
		beforeUpload(file) {
			if (!this.valid.includes(file.type)) {
				this.$message.error('Document file must be a picture, PDF or an office document!');
				return false
			}

			if (file.size / 1024 / 1024 > 4) {
				this.$message.error('Document file size can not exceed 4MB!');
				return false
			}
		},
		downloadFile(name) {
			window.open(`${this.serverPath}${name}`)
		},
		copyToGlobal() {
			if(!this.form.global_name) this.form.global_name = this.form.name.toUpperCase();
		}
	}
}
</script>
